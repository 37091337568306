import React from 'react'
import ReactDOM from 'react-dom/client'
import IONSite from './IONSite'
//import reportWebVitals from './reportWebVitals'
import { QueryClient,  QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { OptinMonsterScript } from '@scrippsproduct/scripps-external-component-library/dist/utils/optinMonsterScript';
import GTM from '@scrippsproduct/scripps-external-component-library/dist/utils/gtm'

import './scss/Settings.scss'
import './scss/Base.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 900000, // 15 mins
        },
    }
  });

const currentEnv = process.env.REACT_APP_ENV;
// Choose envs for OptinMonster -- same allowedEnvs should be set for OptinMonsterReset in IONSite.jsx
const allowedEnvs = ['production', 'staging'];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <GTM />
        <OptinMonsterScript currentEnv={currentEnv} allowedEnvs={allowedEnvs} />
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <BrowserRouter>
                        <IONSite />
                    </BrowserRouter>
                </HelmetProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
