const header = {
    data: new Map([
        ['menuLinks', 
            [
                {
                    type:`internal-link`,
                    href: `/schedule`,
                    label: `Schedule`,
                },
                {
                    type:`internal-link`,
                    href: `/shows`,
                    label: `Shows`,
                },
                {
                    type:`internal-link`,
                    href: `/find-us`,
                    label: `Find Us`,
                },
            ]
        ],
        ['logoItems', 
            [
                {
                    image: {
                        url: `https://ewscripps.brightspotcdn.com/ad/11/12f3118c4d73892f87ec46f9ed13/ion-mystery-logo-2024.svg`,
                        width: 300,
                        height: 119,
                        altText: `ION Mystery logo, go to ION Mystery`,
                    },
                    href: `https://ionmystery.com`,
                    height: 2.2,
                    baselineAdjustment: 0,
                    text: '',
                },
                {
                    image: {
                        url: `https://ewscripps.brightspotcdn.com/49/4d/5a318d674693acf269a7cad94081/button-ion-plus-logo-2x.png`,
                        width: 250,
                        height: 100,
                        altText: `ION Plus logo, go to ION Plus`,
                    },
                    href: `https://ionplustv.com`,
                    height: 2.1,
                    baselineAdjustment: 0,
                    text: '',
                },
            ]
        ]
    ])
}

const footer = {
    data: new Map([
        ['navSections', 
            [
                {
                    "title": "Site Navigation",
                    "links": [
                        {
                            "href": "/schedule",
                            "type": "internal-link",
                            "label": "Schedule"
                        },
                        {
                            "href": "/shows",
                            "type": "internal-link",
                            "label": "Shows"
                        },
                        {
                            "href": "/find-us",
                            "type": "internal-link",
                            "label": "Find Us"
                        }
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Networks",
                    "links": [
                        {
                            "href": "https://iontelevision.com",
                            "type": "internal-link",
                            "label": "ION"
                        },
                        {
                            "href": "https://ionplustv.com",
                            "type": "internal-link",
                            "label": "ION Plus"
                        },
                        {
                            "href": "https://ionmystery.com",
                            "type": "internal-link",
                            "label": "ION Mystery"
                    }
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Privacy & Terms",
                    "links": [
                        {
                            "href": "/privacy-policy",
                            "type": "internal-link",
                            "label": "Privacy Policy"
                        },
                        {
                            "href": "/terms-of-use",
                            "type": "internal-link",
                            "label": "Terms of Use"
                        },
                        {
                            "href": "/privacy-center",
                            "type": "internal-link",
                            "label": "Privacy Center"
                        },
                        {
                            "href": "#",
                            "type": "internal-link",
                            "label": "Privacy Preferences",
                            "classname": "privacy-preferences-trigger",
                        },
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Information",
                    "links": [
                        {
                            "href": "https://support.iontelevision.com/support/solutions",
                            "type": "external-link",
                            "label": "Help / FAQs"
                        },
                        {
                            "href": "https://support.iontelevision.com/support/home",
                            "type": "external-link",
                            "label": "Contact Us"
                        },
                        {
                            "href": "https://scrippsnetworks.com/brand/ion/stations",
                            "type": "external-link",
                            "label": "FCC Applications"
                        }
                    ],
                    "classname": "footer-nav"
                }
            ]
        ],
        ['socialLinks',
            [
                {
                    platform: 'facebook',
                    url: 'https://www.facebook.com/IONTelevision',
                },
                {
                    platform: 'twitter',
                    url: 'https://twitter.com/iontv/',
                    viewBox: '0 0 30 30',
                },
                {
                    platform: 'instagram',
                    url: 'https://www.instagram.com/iontelevision/',
                },
                {
                    platform: 'tiktok',
                    url: 'https://www.tiktok.com/@iontelevision?lang=en',
                    viewBox: '0 0 30 30',
                },
                {
                    platform: 'youtube',
                    url: 'https://www.youtube.com/@ion.television',
                },
            ]
        ],
    ])  
}

const resources = new Map([
    ['header', header],
    ['footer', footer],
])

export {
    resources,
}